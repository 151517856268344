<template>
  <div class="setup">
    <div class="row flex flex-wrap">
      <div class="col s12 m6 l6"><router-link to="/1" class="waves-effect waves-light btn blue lighten-2" >Настроить поле {{hub.players[0].name}}</router-link></div>
      <div class="col s12 m6 l6"><router-link to="/2" class="waves-effect waves-light btn indigo lighten-2" >Настроить поле {{hub.players[1].name}}</router-link></div>
      <div class="col s12 m6 l6"><router-link to="/bombs/1" class="waves-effect waves-light btn blue lighten-1" >Расставить мины у {{hub.players[0].name}}</router-link></div>
      <div class="col s12 m6 l6"><router-link to="/bombs/2" class="waves-effect waves-light btn indigo lighten-1" >Расставить мины у {{hub.players[1].name}}</router-link></div>
      <div class="col m3 l3"></div>
      <div class="col s12 m6 l6"><a class="waves-effect waves-light btn red lighten-1" @click="resetLS">Сбросить и начать новую игру</a></div>
      <div class="col m3 l3"></div>
      <div class="col m3 l4"></div>
      <div class="col s12 m6 l4"><router-link to="/game" class="waves-effect waves-light btn cyan" >Играть!</router-link></div>
      <div class="col m3 l4"></div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import eventHub from "@/eventHub";
export default {
  name: "Setup",
  data(){
    return {
      hub: eventHub,
      dim: 10,
    };
  },
  methods:{
    resetLS(){
      if (confirm('Все данные будут сброшены! Уверены?')) {
        window.localStorage.setItem('config','');
        document.location.reload();
      }
    },
    getCode3(pN) {
      let c = '';
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[pN - 1].field[y][x] === 'ship')
            c += '1';
          else
          if (this.hub.players[pN - 1].field[y][x] === 'bomb')
            c += '2';
          else c += '0';
        }
      }
//      return parseInt(c.substring(0,50), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(50), 3).toString(26).toUpperCase();
      return parseInt(c.substring(0,25), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(25,50), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(50,75), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(75), 3).toString(26).toUpperCase();
    },
    updateGameCode(){
      this.hub.gameCode=this.getCode3(1)+':'+this.getCode3(2);
      //console.log(this.hub.gameCode);
    },
  },
  mounted() {
    this.hub.$on('updateGameCode',this.updateGameCode);
  },

}
</script>

<style scoped lang="scss">
.btn{
  width: 100%;
  margin-bottom: 5px;
}
</style>